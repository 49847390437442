






















































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { RequestPaging } from '@/utils/util'
import LsPagination from '@/components/ls-pagination.vue'
import { apiSelectUserLists } from '@/api/user/user'
@Component({
    components: {
        LsPagination
    }
})
export default class UserSelect extends Vue {
    @Prop({ default: '5vh' }) top!: string | number //弹窗的距离顶部位置

    @Prop({ default: 0 }) is_distribution!: string | number //用户选择是否非分销商（目前在分销->分销商中以及优惠券，卖家发放优惠券选择的用户中有使用到

    /** S Data **/
    visible = false //是否

    userSearchData = {
        // 搜索的数据
        nickname: ''
    }

    pager = new RequestPaging() // 用户数据分页

    selectIds: any[] = [] //当前选中的用户数组

    /** E Data **/

    /** S Computed **/

    // 获取当前的选择
    get selectItem() {
        return (row: any) => {
            return this.selectIds.some(item => item.id === row.id)
        }
    }

    // 获取全选
    get selectAll() {
        const ids: any[] = this.selectIds.map(item => item.id)
        return this.pager.lists.every(item => ids.includes(item.id))
    }

    // 设置全选
    set selectAll(val) {
        if (val) {
            // 深度克隆再赋值，防止table表数据跟着更新
            this.selectIds = JSON.parse(JSON.stringify(this.pager.lists))
        } else {
            this.selectIds = []
        }
    }

    /** E Computed **/

    /** S Method **/

    // 选择当前用户
    handleSelect(event: boolean, row: any) {
        if (event) {
            this.selectIds.push(row)
        } else {
            const index = this.selectIds.findIndex(item => item.id === row.id)
            if (index != -1) {
                this.selectIds.splice(index, 1)
            }
        }
    }

    // 移除选中的用户
    removeSelectGoods(id: number) {
        const index: any = this.selectIds.map(item => item.id == id)
        if (index != -1) {
            this.selectIds.splice(index, 1)
        }
    }

    // 获取用户数据
    getUserInfoList(page?: number): void {
        page && (this.pager.page = page)
        this.pager.request({
            callback: apiSelectUserLists,
            params: {
                ...this.userSearchData,
                is_distribution: this.is_distribution
            }
        })
    }

    // 重置搜索
    resetuserSearchData() {
        Object.keys(this.userSearchData).map(key => {
            this.$set(this.userSearchData, key, '')
        })
        this.getUserInfoList()
    }

    // 选中用户
    selectionChange(val: any[]) {
        this.selectIds = val.map(item => item)
    }

    // 点击取消
    handleEvent(type: 'cancel' | 'confirm') {
        if (type === 'cancel') {
            this.selectIds = []
            this.close()
        }
        if (type === 'confirm') {
            this.$emit('input', this.selectIds)
            this.close()
        }
    }

    // 打开弹窗
    onTrigger() {
        this.visible = true
        this.getUserInfoList()
    }

    // 关闭用户选择弹窗
    close() {
        this.visible = false
    }

    /** E Method **/

    created() {
        this.getUserInfoList()
    }
}
