









































































































import { Component, Vue } from 'vue-property-decorator'
import AreaSelect from '@/components/area-select.vue'
import UserSelect from '@/components/marketing/user-select.vue'
import { apiCouponInfo, apiCouponSend } from '@/api/marketing/coupon'
@Component({
    components: {
        AreaSelect,
        UserSelect
    }
})
export default class AddSupplier extends Vue {
    /** S Data **/

    id!: any //当前的ID

    couponInfo: any = {
        id: this.id, //优惠券ID
        send_user_num: '', //必选	float	每人发放数量
        send_user: [] //用户的ID数组, use_goods_type=2 / 3 时必填, 且数组不可为空
    }

    userSelectData: any = []

    /** E Data **/

    /** S Method **/

    submit(formName: string) {
        // 验证表单格式是否正确
        // const refs = this.$refs[formName] as HTMLFormElement;
        // refs.validate((valid: boolean): any => {
        //     if (!valid) return;
        this.couponSend()
        // });
    }

    // 添加优惠券
    couponSend() {
        if (this.couponInfo.send_user_num == undefined) {
            return this.$message.error('请输入要发放的优惠券数量！!')
        }
        if (
            this.couponInfo.send_user_num < 0 ||
            isNaN(this.couponInfo.send_user_num)
        ) {
            return this.$message.error('不允许输入负数与非数字')
        }
        if (this.userSelectData.length == 0) {
            return this.$message.error('请输入要发放的用户！!')
        }
        const params = {
            id: this.id, //优惠券ID
            send_user_num: this.couponInfo.send_user_num, //必选	float	每人发放数量
            send_user: this.userSelectData.map((item: any) => item.id) //用户的ID数组, use_goods_type=2 / 3 时必填, 且数组不可为空
        }
        apiCouponSend({ ...params }).then(res => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 获取优惠券信息
    getCouponInfo() {
        apiCouponInfo(this.id).then((res: any) => {
            this.couponInfo = res
        })
    }

    /** E Method **/

    created() {
        this.id = this.$route.query.id
        this.id && this.getCouponInfo()
    }
}
